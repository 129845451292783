import Link from "next/link";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { Image } from "@nextui-org/react";

interface FeatureCardProps {
  title: string;
  description: string;
  price: string;
  link: string;
  imageSrc: string;
}

const FeatureCard: React.FC<FeatureCardProps> = (props) => {
  const { title, description, price, link, imageSrc } = props;

  return (
    <div className="select-none flex justify-between flex-1 h-60 lg:h-80 p-8 rounded-sm bg-gray-100 transition-all duration-500">
      <div className="flex flex-col flex-1 justify-between">
        <div>
          <Link href={link}>
            <h3 className="text-2xl font-semibold hover:text-icon transition-all duration-500">
              {title}
            </h3>
          </Link>
          <p className="mt-3">{description}</p>
          <p className="font-semibold">from ${price}</p>
        </div>
        <Link href={link}>
          <div className="flex items-center gap-2 font-semibold hover:text-icon transition-all duration-500">
            Shop products <MdOutlineArrowForwardIos />
          </div>
        </Link>
      </div>
      <div className="flex-1 max-w-[280px] flex items-center justify-center">
        <Image alt={title} width={280} height={320} src={imageSrc} isZoomed />
      </div>
    </div>
  );
};

export default FeatureCard;
