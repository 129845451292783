import Image from "next/image";
import HeroImg from "@/assets/homepage/hero2.jpg";
import Info from "./components/Info";

const Hero2 = () => (
  <div className="relative overflow-hidden container mx-auto my-8 lg:my-24 transition-all duration-500">
    <div className="z-10 absolute top-4 md:top-16 md:left-4 lg:left-16 bg-white bg-opacity-90 w-full max-w-[600px] p-16 transition-all duration-500">
      <Info />
    </div>
    <div className="relative w-full h-[650px]">
      <Image
        src={HeroImg.src}
        alt="hero"
        width={1600}
        height={1067}
        className="absolute inset-0 w-full h-full object-cover object-center"
      />
    </div>
  </div>
);

export default Hero2;
