import React, { useEffect } from "react";
import { gsap, Power1, Power2, Expo } from "gsap";

const PlayButton: React.FC = () => {
  useEffect(() => {
    gsap.set(".play-circle-01", {
      rotation: 90,
      transformOrigin: "center",
    });

    gsap.set(".play-circle-02", {
      rotation: -90,
      transformOrigin: "center",
    });

    gsap.set(".play-perspective", {
      xPercent: 6.5,
      scale: 0.175,
      transformOrigin: "center",
      perspective: 1,
    });

    gsap.set(".play-video", {
      visibility: "hidden",
      opacity: 0,
    });

    gsap.set(".play-triangle", {
      transformOrigin: "left center",
      transformStyle: "preserve-3d",
      rotationY: 10,
      scaleX: 2,
    });

    const rotateTL = gsap
      .timeline({ paused: true })
      .to(
        ".play-circle-01",
        {
          duration: 0.7,
          opacity: 0.1,
          rotation: "+=360",
          strokeDasharray: "456 456",
          ease: Power1.easeInOut,
        },
        0,
      )
      .to(
        ".play-circle-02",
        {
          duration: 0.7,
          opacity: 0.1,
          rotation: "-=360",
          strokeDasharray: "411 411",
          ease: Power1.easeInOut,
        },
        0,
      );

    const openTL = gsap
      .timeline({ paused: true })
      .to(
        ".play-backdrop",
        {
          duration: 1,
          opacity: 0.95,
          visibility: "visible",
          ease: Power2.easeInOut,
        },
        0,
      )
      .to(
        ".play-close",
        {
          duration: 1,
          opacity: 1,
          ease: Power2.easeInOut,
        },
        0,
      )
      .to(
        ".play-perspective",
        {
          duration: 1,
          xPercent: 0,
          scale: 1,
          ease: Power2.easeInOut,
        },
        0,
      )
      .to(
        ".play-triangle",
        {
          duration: 1,
          scaleX: 1,
          ease: Expo.easeInOut,
        },
        0,
      )
      .to(
        ".play-triangle",
        {
          duration: 1,
          rotationY: 0,
          ease: Expo.easeInOut,
        },
        0,
      )
      .to(
        ".play-video",
        {
          duration: 1,
          visibility: "visible",
          opacity: 1,
        },
        0.5,
      );

    const button = document.querySelector(".play-button");
    const backdrop = document.querySelector(".play-backdrop");
    const close = document.querySelector(".play-close");

    const handleMouseOver = () => rotateTL.play();
    const handleMouseLeave = () => rotateTL.reverse();
    const handleClick = () => openTL.play();
    const handleBackdropClick = () => openTL.reverse();
    const handleCloseClick = (e: Event) => {
      e.stopPropagation();
      openTL.reverse();
    };

    button?.addEventListener("mouseover", handleMouseOver);
    button?.addEventListener("mouseleave", handleMouseLeave);
    button?.addEventListener("click", handleClick);
    backdrop?.addEventListener("click", handleBackdropClick);
    close?.addEventListener("click", handleCloseClick);

    return () => {
      button?.removeEventListener("mouseover", handleMouseOver);
      button?.removeEventListener("mouseleave", handleMouseLeave);
      button?.removeEventListener("click", handleClick);
      backdrop?.removeEventListener("click", handleBackdropClick);
      close?.removeEventListener("click", handleCloseClick);
    };
  }, []);

  return (
    <div className="play-button-container">
      <div className="play-backdrop"></div>
      <div className="play-button">
        <svg className="play-circles" viewBox="0 0 152 152">
          <circle
            className="play-circle-01"
            fill="none"
            stroke="#fff"
            strokeWidth="3"
            strokeDasharray="343 343"
            cx="76"
            cy="76"
            r="72.7"
          />
          <circle
            className="play-circle-02"
            fill="none"
            stroke="#fff"
            strokeWidth="3"
            strokeDasharray="309 309"
            cx="76"
            cy="76"
            r="65.5"
          />
        </svg>
        <div className="play-perspective">
          <button className="play-close" aria-label="Play button"></button>
          <div className="play-triangle">
            <div className="play-video">
              <iframe
                width="600"
                height="400"
                src="https://www.youtube-nocookie.com/embed/Bjql0-Bq25w"
                allow="autoplay; encrypted-media"
                allowFullScreen
                loading="lazy"
                sandbox="allow-same-origin allow-scripts allow-presentation"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayButton;
