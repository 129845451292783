const Right = () => (
  <div className="bg-black text-white bg-opacity-80 px-2 lg:px-6 pt-2 lg:pt-4 pb-4 lg:pb-7 w-40 lg:w-52 transition-all duration-500">
    <p className="text-sm lg:text-base font-bold transition-all duration-500">
      CANDY
    </p>
    <p className="text-sm lg:text-base font-semibold transition-all duration-500">
      Chair with armrests from $49
    </p>
  </div>
);

export default Right;
