import Banner from "./components/Banner";
import Features from "./components/Features";
import Hero from "./components/Hero";
import Hero2 from "./components/Hero2";
import PopularProducts from "./components/PopularProducts";
import Story from "./components/Story";
import Touch from "./components/Touch";
import Review from "./components/Review";

export { Banner, Features, Hero, Hero2, PopularProducts, Story, Touch, Review };
