import FeatureCard from "./components/FeatureCard";

const Features = () => (
  <div className="w-full flex flex-col gap-6 container mx-auto pt-16">
    <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 transition-all duration-500">
      <div className="col-span-1 lg:col-span-2 transition-all duration-500">
        <FeatureCard
          title="Coffee & Side Table"
          description="Make your room to numerous décor themes."
          price="139.99"
          link="/category/sideend-tables/product"
          imageSrc="/kiama.webp"
        />
      </div>
      <div className="col-span-1 ">
        <FeatureCard
          title="Dining chair"
          description={`Add a touch of elegance to your dining space.`}
          price="49.99"
          link="/category/dining-chair/product"
          imageSrc="/bella.webp"
        />
      </div>
      <div className="col-span-1">
        <FeatureCard
          title="Wardrobe"
          description="Maximize your bedroom storage."
          price="259.00"
          link="/category/wardrobes/product"
          imageSrc="/wardrobe.webp"
        />
      </div>
      <div className="col-span-1 lg:col-span-2 transition-all duration-500">
        <FeatureCard
          title="Sofa"
          description="Revitalize your living room with sofas that provide comfort and style."
          price="499"
          link="/category/sofa/product"
          imageSrc="/stella.webp"
        />
      </div>
      <div className="col-span-1 lg:col-span-2 transition-all duration-500">
        <FeatureCard
          title="Bed frame"
          description="Create a cozy and stylish bedroom retreat."
          price="149"
          link="/category/bed-frames/product"
          imageSrc="/kiama-bed.webp"
        />
      </div>
    </div>
    {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-6 transition-all duration-500">
      <BenefitCard
        title="GET REWARDED"
        pitch="Earn 10% Back in Rewards"
        description="Valid online & in-store with select styles."
        buttonText="Learn More"
        link="/"
        bgImage={Benefit1.src}
      />
      <BenefitCard
        title="PARTNERSHIP"
        pitch="Join our free loyalty program"
        description="Because Priceworth Furniture members get more."
        buttonText="Join the Program"
        link="/"
        bgColor="#E7D8D6"
      />
    </div> */}
  </div>
);

export default Features;
