import { MdOutlineArrowForwardIos } from "react-icons/md";
import Link from "next/link";
import { gsap } from "gsap";
import { useRef } from "react";
import { useGSAP } from "@gsap/react";
import { Button } from "@nextui-org/react";

interface LeftProps {
  currentIndex: number;
}

const Left: React.FC<LeftProps> = (props) => {
  const { currentIndex } = props;
  const containerRef = useRef(null);

  const slides = [
    {
      small1: "Quick parcel delivery,",
      small2: "from $49",
      title: "Get used to better living space",
      description1: "Shop quality, stylish and smart furniture here.",
      description2: "Check out fresh design ideas.",
      link: "/category",
    },
    {
      small1: "Furnish Your Living Room,",
      small2: "with Ease",
      title: "Relax and Entertain in Comfort",
      description1: "Transform your living room into a stylish haven.",
      description2: "Shop our latest designs and elevate your space.",
      link: "/category/living-and-dining-room",
    },
    {
      small1: "Discover Dining Furniture That",
      small2: "Fits Your Style!",
      title: "Dine Together in Style and Comfort",
      description1:
        "Create a warm, inviting atmosphere with our stylish dining sets,",
      description2: "perfect for every occasion.",
      link: "/category/dining-and-kitchen",
    },
    {
      small1: "Top-Rated Products,",
      small2: "Just for You!",
      title: "Create Your Perfect Sleep Sanctuary",
      description1:
        "Create the ultimate sleep sanctuary with our curated bedroom collections.",
      description2: "Rest well, live well.",
      link: "/category/bedroom-and-mattress",
    },
    {
      small1: "Easy Returns for Wardrobe Purchases.",
      small2: "Shop Confidently!",
      title: "Maximize Space with Stylish Wardrobes",
      description1: "Maximize your space with sleek, functional wardrobes.",
      description2: "Designed for both style and convenience.",
      link: "/category/wardrobes/product",
    },
    {
      small1: "Upgrade Your Home Today:",
      small2: " Flexible Payment Plans Available",
      title: "Efficient Appliances for Everyday Life",
      description1:
        "Discover smart, efficient appliances that make life easier,",
      description2: "so you can focus on what matters most.",
      link: "/category/appliance",
    },
  ];

  useGSAP(
    () => {
      const items = gsap.utils.toArray(".item-mama");
      gsap.fromTo(
        items,
        { y: 50, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          stagger: 0.5,
          ease: "power1.out",
          duration: 1,
          delay: 0.2,
        },
      );
    },
    { dependencies: [currentIndex], scope: containerRef },
  );

  return (
    <div
      ref={containerRef}
      className="bg-white bg-opacity-60 py-4 px-2 lg:py-16 lg:px-8 transition-all duration-500"
    >
      <p className="item-mama opacity-0 text-sm md:text-base lg:text-lg font-bold transition-all duration-500">
        {slides[currentIndex].small1}{" "}
        <span className="text-icon">{slides[currentIndex].small2}</span>
      </p>
      <h2 className="item-mama opacity-0 text-base sm:text-2xl md:text-4xl font-bold mt-2 lg:text-6xl transition-all duration-500">
        {slides[currentIndex].title.split(" ").slice(0, 3).join(" ")} <br />{" "}
        {slides[currentIndex].title.split(" ").slice(3).join(" ")}
      </h2>
      <p className="item-mama opacity-0 mt-3 sm:mt-6 text-sm lg:text-base transition-all duration-500">
        {slides[currentIndex].description1}
      </p>
      <p className="item-mama opacity-0 text-sm lg:text-base transition-all duration-500">
        {slides[currentIndex].description2}
      </p>
      <Link href={slides[currentIndex].link}>
        <Button className="item-mama opacity-0 bg-gray-950 text-white font-semibold text-sm lg:text-base rounded-md px-2 sm:px-4 pt-6 pb-7 flex items-center gap-4 mt-4 sm:mt-8 hover:bg-icon transition-all duration-500">
          Start Shopping <MdOutlineArrowForwardIos />
        </Button>
      </Link>
    </div>
  );
};

export default Left;
