import Collapse from "@/components/Shares/Collapse";

const Info = () => (
  <>
    <h3 className="text-3xl font-bold mt-2">Space & stock for designs.</h3>
    <p className="mt-2">
      Start this <b>journey with</b> the efficient weather is amazing, walk with
      me through the pathway.
    </p>
    <div className="bg-white px-4 py-5 flex flex-col gap-3 rounded-sm mt-6">
      <Collapse
        title="Secure payment"
        detail="Experience ultimate peace of mind with our secure payment system, ensuring your transactions are protected every step of the way."
      />
      <Collapse
        title="Fast delivery"
        detail="Enjoy lightning-fast delivery with our service, bringing your purchases to your doorstep swiftly and efficiently every single time."
      />
    </div>
  </>
);

export default Info;
