import { MdOutlineArrowForwardIos } from "react-icons/md";
import Link from "next/link";
import Image from "next/image";

interface BlogProps {
  type: string;
  author: string;
  date: string;
  title: string;
  description: string;
  link: string;
  image: string;
}

const Blog: React.FC<BlogProps> = (props) => {
  const { type, author, date, title, description, link, image } = props;
  return (
    <div className="relative">
      <div className="absolute transform top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 lg:left-0 lg:translate-x-0 w-full max-w-[380px] bg-white bg-opacity-90 px-6 py-4 transition-all duration-500">
        <button className="bg-black px-2 py-1 text-xs text-icon font-bold rounded">
          {type}
        </button>
        <div className="flex my-3">
          <p className="font-bold">{author}</p>
          <span className="select-none px-1">&#183;</span>
          <p className="text-gray-600">{date}</p>
        </div>
        <Link
          className="font-bold text-2xl hover:text-icon transition-all duration-500"
          href={link}
          aria-label={title}
        >
          {title}
        </Link>
        <p className="mt-3">{description}</p>
        <Link
          href={link}
          className="flex items-center gap-3 font-bold mt-3 hover:text-icon transition-all duration-500"
          aria-label={`Read more about ${title}`}
        >
          <span className="sr-only">Read more about {title}</span>
          <span aria-hidden="true">Read More</span>
          <MdOutlineArrowForwardIos />
        </Link>
      </div>
      <Image
        src={image}
        alt={title}
        width={1500}
        height={1038}
        className="w-full lg:w-3/4 transition-all duration-500 ml-auto"
      />
    </div>
  );
};

export default Blog;
