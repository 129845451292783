import Monica from "@/components/Shares/CarouselSlider/images/carouselSlider/monica.jpg";
import Nicola from "@/components/Shares/CarouselSlider/images/carouselSlider/nicola.jpg";
import Rebecca from "@/components/Shares/CarouselSlider/images/carouselSlider/rebecca.jpg";
import Luna from "@/components/Shares/CarouselSlider/images/carouselSlider/luna.jpg";
import Oliver from "@/components/Shares/CarouselSlider/images/carouselSlider/oliver.jpg";
import Fandi from "@/components/Shares/CarouselSlider/images/carouselSlider/fandi.jpg";
import Stella from "@/components/Shares/CarouselSlider/images/carouselSlider/stella.jpg";

const slideData = [
  {
    imageSrc: Monica.src,
    title: "Monica",
    description:
      "The Monica Lift Storage Collection bed frame features Australian inspired furniture engineering.",
    link: "/product/monica-gas-lift-pu-leather-bed-frame-queen",
  },
  {
    imageSrc: Nicola.src,
    title: "Nicola",
    description:
      "Nicole Fabric bed with drawers. Pieces feature side rails lined with a fabric beige finish and padded surfaces free of sharp edges.",
    link: "/product/nicola-fabric-bed-with-4-drawers-queen-grey",
  },
  {
    imageSrc: Rebecca.src,
    title: "Rebecca",
    description:
      "Absolutely timeless and chic, this bed comes with an intelligent gas lift storage mechanism for all your bedsheets, pillows and more.",
    link: "/product/rebecca-light-grey-fabric-gas-lift-queen",
  },
  {
    imageSrc: Luna.src,
    title: "Luna",
    description:
      "Bonnell spring Mattress bring for you an amazing comfort in your sleeping times.",
    link: "/product/luna-queen-1680-fire-retardant-mattress",
  },
  {
    imageSrc: Oliver.src,
    title: "Oliver",
    description:
      "Our retro Oliver dining table is as simple as it gets and yet it displays all the charm and nuances of a modernistic design that is timeless and stylish.",
    link: "/product/oliver-120cm-rectangle-dining-table",
  },
  {
    imageSrc: Fandi.src,
    title: "Fandi",
    description:
      "An elegant design that will stand the test of time and taste. This arm chair is perfect for charming appeal to your home.",
    link: "/product/fandi-arm-chair-pink-colour",
  },
  {
    imageSrc: Stella.src,
    title: "Stella",
    description:
      "Need a couch that’s just as charming and fun as you are? Look no further. Family time, movie night, game night: they all happen right here on this sleek, pastel-colored 3-seater sofa.",
    link: "/product/stella-3-seater-sofa",
  },
];

export default slideData;
