import CarouselSlider from "@/components/Shares/CarouselSlider";

const PopularProducts = () => (
  <div className="flex flex-col items-center bg-gray-100 w-full py-16">
    <h2 className="text-4xl font-bold">Popular Products</h2>
    <p className="text-lg font-semibold mt-2">
      Explore Hot Picks, Furnish Your Future!
    </p>
    <div className="py-8 w-full overflow-hidden">
      <CarouselSlider />
    </div>
  </div>
);

export default PopularProducts;
