import { Button } from "@nextui-org/react";
import Link from "next/link";
import { ReactGoogleReviews } from "react-google-reviews";

const Review = () => {
  return (
    <div className="container flex flex-col items-center mx-auto py-8 mt-6 shadow-card overflow-hidden">
      <div className="mb-6">
        <ReactGoogleReviews
          layout="badge"
          featurableId="1f55ddbd-34cb-40dc-bb78-bf531fa6590e"
        />
      </div>
      <div className="hidden md:block">
        <ReactGoogleReviews
          layout="carousel"
          featurableId="1f55ddbd-34cb-40dc-bb78-bf531fa6590e"
        />
      </div>
      <Link
        href="https://g.page/r/CRRQ-rKrZXVlEAE/review"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button className="bg-blue-600 font-semibold text-white text-lg rounded-md px-8 py-6">
          Write a review
        </Button>
      </Link>
    </div>
  );
};

export default Review;
